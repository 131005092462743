import * as React from 'react';
import {Chatbot, ChatbotSettings} from '../../../model/Chatbot';
import { useChatbotApiClient } from '../../../clients/ChatbotApiClient';
import { useTranslation } from 'react-i18next';
import {ChangeEvent, useState} from 'react';
import {
    Alert, CircularProgress, Dialog, DialogActions, DialogContent,
    FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography
} from '@mui/material';
import DialogHeader from '../../../shared/components/dialogs/DialogHeader';
import Headline from '../../../shared/components/Headline';
import { ErrorResponse } from '../../../clients/error/ErrorResponse';
import CopyInputField from '../../../shared/components/CopyInputField';
import { getLinkForCurrentHost } from '../../../shared/tools/routeTools';
import ConfirmDialog from '../../../shared/components/dialogs/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Chat from "../../chat/chatbot/Chat";

export interface ISettingsModalProps {
    chatbot: Chatbot
    open?: boolean
    onClose?(): void
    onBotChange?(updatedBot: Chatbot): void
}

const SettingsModal: React.FunctionComponent<ISettingsModalProps> = (props: ISettingsModalProps) => {

    const chatbotClient = useChatbotApiClient({ preventNotification: true })

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [pageError, setPageError] = useState<string>()

    const [availabilityLoading, setAvailabilityLoading] = useState(false)
    const [trainingStatusLoading, setTrainingStatusLoading] = useState(false)
    const [savingLoading, setSavingLoading] = useState(false)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const [deletingBot, setDeletingBot] = useState(false)
    const [trainingStatus, setTrainingStatus] = useState<string>(props.chatbot.trainingStatus + "")
    
    const [data, setData] = useState<ChatbotSettings>(props.chatbot.settings!);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        
        if (name === 'web_rag_selectors') {
            const newVal = value.split(",")
            setData((prevData) => ({
                ...prevData,
                [name]: newVal,
            }));
        } else {
            setData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleColorChange = (hex: string, name: string) => {
        setData((prevData) => ({
            ...prevData,
            colors: {
                ...prevData.colors,
                [name]: hex,
            },
        }));
    };

    const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const onClose = () => {
        setPageError(undefined)
        props.onClose && props.onClose()
    }
    
    const saveSettings = () => {
        setSavingLoading(true)
        chatbotClient.setChatbotSettings(props.chatbot.id, data)
            .then((bot) => props.onBotChange && props.onBotChange(bot))
            .catch((err: ErrorResponse) => {
                debugger;
                setPageError(err.message)
            })
            .finally(() => setSavingLoading(false))
    }

    const changeTrainingStatus = (newStatus: string) => {
        if (!trainingStatusLoading) {
            setTrainingStatus(newStatus)
            setTrainingStatusLoading(true)
            chatbotClient.setChatbotTrainingStatus(props.chatbot.id, newStatus)
                .then((bot) => props.onBotChange && props.onBotChange(bot))
                .catch((err: ErrorResponse) => {
                    debugger;
                    setPageError(err.message)
                })
                .finally(() => setTrainingStatusLoading(false))
        }
    }

    const toggleAvailability = () => {
        if (!availabilityLoading) {
            setAvailabilityLoading(true)
            chatbotClient.setChatbotAvailability(props.chatbot.id, !props.chatbot.settings?.publicAvailable)
                .then((bot) => props.onBotChange && props.onBotChange(bot))
                .catch((err: ErrorResponse) => {
                    debugger;
                    setPageError(err.message)
                })
                .finally(() => setAvailabilityLoading(false))
        }
    }

    const deleteChatbot = () => {
        if (!deletingBot) {
            setDeletingBot(true)
            chatbotClient.deleteChatbot(props.chatbot.id)
                .then(() => navigate('/chatBots'))
                .catch((err: ErrorResponse) => setPageError(err.message))
                .finally(() => setDeletingBot(false))
        }
    }

    return (
        <>
            <Dialog
                open={!!(props.open)}
                PaperProps={{ sx: { backgroundColor: "white" } }}
                disableEnforceFocus
                fullWidth
                maxWidth={'md'}
                scroll="paper"
                onClose={onClose}
            >
                <DialogHeader
                    headline={t('chatbot.settingsModal.customizeYourBot')}
                    closeDialog={onClose} />
                <DialogContent>
                    <Grid container spacing={2} justifyContent={"center"}>
                        {pageError &&
                            <Grid item xs={12} container justifyContent='center'>
                                <Alert severity="error">
                                    {pageError}
                                </Alert>
                            </Grid>
                        }

                        {/* General Settings */}
                        
                        <Grid item xs={12} justifyContent='right' sx={{ }}>
                            <Headline title={"General Settings"} size='section' />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="Training Status-label">{"Training Status"}</InputLabel>
                                <Select
                                    labelId="Training Status-label"
                                    value={trainingStatus + ""}
                                    onChange={(e) => changeTrainingStatus(e.target.value)}
                                    label={"Training Status"}
                                >
                                    {["NOT_TRAINED", "PENDING", "TRAINING", "TRAINED", "FAILED"]
                                        .map(e => <MenuItem key={e} value={e}>
                                        {e}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <TextField fullWidth label="NLP Task" name="nlpTask" 
                                       value={data.nlpTask} onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="NLP Model" name="nlpModel"
                                value={data.nlpModel} onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Temperature" name="temperature"
                                value={data.temperature} onChange={handleChange}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField label="Default System Promt (de)" name="defaultSystemPromptDe"
                                    value={data.defaultSystemPromptDe} onChange={handleChange}
                                    multiline rows={6}
                                />
                            </FormControl>
                        </Grid>

                        {/* Web-Rag */}
                        <Grid item xs={12} justifyContent='right' sx={{ }}>
                            <Headline title={"Realtime-RAG"} size='section' />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Always relate your answer to..." name="web_rag_city"
                                       value={data.web_rag_city} onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth label="Base-URL" name="web_rag_base_url"
                                       value={data.web_rag_base_url} onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField fullWidth label="Element Selector(s)" name="web_rag_selectors"
                                       value={data.web_rag_selectors?.join(", ")} onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField fullWidth label="Factor" name="web_rag_max_search_results"
                                       value={data.web_rag_max_search_results} onChange={handleChange}
                                       type="number"
                            />
                        </Grid>

                        {/* Design */}
                        <Grid item xs={12} justifyContent='right' sx={{ }}>
                            <Headline title={"Design"} size='section' />
                        </Grid>
                        
                        <Grid item xs={6} justifyContent='right' sx={{ }}>
                            <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                <TextField label="Icon URL" name="icon"
                                    value={data.icon} onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                <TextField label="Title Text" name="titleText"
                                    value={data.titleText} onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid container spacing={2} justifyContent={"center"}>
                                {data.colors && [
                                    "primary", // "Headline + Question background",
                                    "bot", // "Answer Background",
                                    "light", // "Headline + Question text",
                                    "botText", // "Answer text",
                                    
                                    "timestamp" // "Timestamp text"
                                ].map((colorKey) => (<>
                                    <Grid item xs={2} key={colorKey}>
                                        <input type="color" value={(data.colors as Record<string, string>)[colorKey]}
                                               onChange={(color) => handleColorChange(color.target.value, colorKey)}
                                        />
                                    </Grid>
                                    <Grid item xs={10} key={colorKey}>
                                        <Typography>{(
                                            colorKey === "bot" ? "Background: Answer " 
                                                : (colorKey === "botText" ? "Text: Answer " 
                                                : (colorKey === "light" ? "Text: Headline + Question " 
                                                : (colorKey === "primary" ? "Background: Headline + Question " 
                                                : (colorKey === "timestamp" ? "Text: Timestamp " 
                                                : colorKey)))))}</Typography>
                                        
                                    </Grid>
                                </>))}
                            </Grid>

                            {/*Welcome message*/}
                            <Grid item xs={12} sx={{marginTop: "20px", marginBottom: "20px"}}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField label="Welcome Messages" name="welcome_rag"
                                               value={data.welcome_rag && data.welcome_rag["de"] && data.welcome_rag["de"].join('\n')} onChange={handleChange}
                                               multiline rows={6}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {/* Chatbot "preview" */}
                        <Grid item xs={6} justifyContent='right' >
                            <Chat 
                                botId={props.chatbot.id}
                                botName={props.chatbot.name}
                                botDescription={props.chatbot.description}
                                botSettings={data}
                                nlpTask={"RAG"}
                                undecorated={true}
                                questionToAsk={"Tell me a joke!"}
                            />
                        </Grid>

                        <Grid item xs={12} justifyContent='right' sx={{ }}>
                            <Headline title={"Expert Settings"} size='section' />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField label="Waiting Messages" name="w8messages"
                                           value={data.w8messages && data.w8messages.join('\n')} onChange={handleChange}
                                           multiline rows={6}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography>Check Answers</Typography>
                            
                        </Grid>
                        
                        <Grid item xs={4}>
                            <Typography>Additional Sources</Typography>
                            <Switch
                                checked={data.addSources}
                                onChange={handleSwitchChange}
                                name="addSources"
                            />
                        </Grid>
                        
                        <Grid item xs={4}>
                            <Typography>Check Answers</Typography>
                            <Switch
                                checked={data.checkAnswers}
                                onChange={handleSwitchChange}
                                name="checkAnswers"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Check Answers</Typography>
                            <Switch
                                checked={data.checkAnswers}
                                onChange={handleSwitchChange}
                                name="checkAnswers"
                            />
                        </Grid>


                        <Grid item xs={12} >
                            <Headline title={t('chatbot.settingsModal.publicAvailability')} size='section' />
                            <FormGroup>
                                <FormControlLabel
                                    onClick={toggleAvailability}
                                    control={availabilityLoading ? <CircularProgress size='25px' sx={{ margin: '0.25em' }} /> : <Switch checked={props.chatbot.settings?.publicAvailable} />}
                                    label={props.chatbot.settings?.publicAvailable ? t('chatbot.settingsModal.publicAvailable') : t('chatbot.settingsModal.notPublicAvailable')}
                                />
                            </FormGroup>
                            {props.chatbot.settings?.publicAvailable &&
                                <CopyInputField
                                    label={t('chatbot.settingsModal.publicLink')}
                                    text={getLinkForCurrentHost(`/bot/${props.chatbot.id}/chat`)} />
                            }
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Headline title={t('chatbot.settingsModal.deleteYourBot')} size='section' />
                            <LoadingButton loading={deletingBot} variant="contained" color="error" onClick={() => setConfirmDeleteOpen(true)}>
                                {t('chatbot.deleteBot')}
                            </LoadingButton>
                        </Grid>
                        
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={"right"}>
                        <Grid item>
                            <LoadingButton loading={savingLoading} onClick={() => saveSettings()} variant="contained">
                                {"Save"}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                confirmQuestion={t('chatbot.confirmDelete', { botName: props.chatbot.name })}
                confirmPhrase={props.chatbot.name}
                onConfirm={() => deleteChatbot()}
                severity={'error'} />
        </>
    );
}

export default SettingsModal;