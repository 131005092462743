import { FileEntity } from "./FileEntity"
import { User } from "./User"

export interface ChatbotPagination {
    totalResults: number
    page: number
    totalPages: number
    pageSize: number
    chatbots: Chatbot[]
}

export interface Chatbot {
    id: string
    name: string
    description: string
    createdAt?: string
    createdBy?: User
    settings?: ChatbotSettings
    queryCounter?: number
    files?: FileEntity[]
    trainingStatus: TrainingStatus
}

export interface ChatbotSettings {
    publicAvailable?: boolean
    addSources?: boolean
    checkAnswers?: boolean
    temperature?: number
    defaultSystemPrompt?: string
    chunksToFetch?: number // 5;
    maxNewTokens?: number // 1500;
    defaultSystemPromptDe?: string
    defaultSystemPromptEn?: string 
    nlpTask: string // "RAG";
    nlpModel: string
    welcome_rag?: { [key: string]: string[] }
    welcome_cis?: { [key: string]: string[] }

    titleText?: string
    icon?: string
    colors?: { [key: string]: string }
    descriptions?: { [key: string]: string[] }
    w8messages?: string[]

    web_rag_base_url?: string
    web_rag_city?: string
    web_rag_selectors?: string[]
    web_rag_max_search_results?: number
    
    mirrorIFrameIcon?: boolean
    showBookAnimationAboveIFrameIcon?: boolean
    showIFrameHelperText?: boolean
}

export interface FeedbackContent {
    createdAt: string
    feedback: string
    chatPdfAsBase64: string
}

export enum TrainingStatus {
    NOT_TRAINED = 'NOT_TRAINED',
    PENDING = 'PENDING',
    TRAINING = 'TRAINING',
    TRAINED = 'TRAINED',
    FAILED = 'FAILED'
}


export enum Embeddings {
    OPEN_AI_TE_3_LARGE = "OpenAI/text-embedding-3-large",
    OPEN_AI_TE_3_SMALL = "OpenAI/text-embedding-3-small",
    OPEN_AI_TE_ADA_2 = "OpenAI/text-embedding-ada-002",
    SENTENCE_TRANSFORMERS_ALL_MINILM_L6_V2 = "sentence-transformers/all-MiniLM-L6-v2",
    HKUNLP_INSTRUCTOR_LARGE = "hkunlp/instructor-large",
    NOMIC_AI_EMBED_TEXT_1_5 = "nomic-ai/nomic-embed-text-v1.5",
    INTFLOAT_E5_BASE = "intfloat/e5-base",
}

export interface TrainingSettings {
    embeddings: Embeddings
    chunkSize: number
    chunkOverlap: number
}

export const botIsTraining = (bot: Chatbot) => {
    return bot.trainingStatus === TrainingStatus.PENDING || bot.trainingStatus === TrainingStatus.TRAINING

}