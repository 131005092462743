import React from "react";
import Signup from "../../pages/signup/Signup";
import Home from "../../pages/home/homepage/Home";
import Dashboard from "../../pages/app/Dashboard";
import Login from "../../pages/login/Login";
import { Role } from "../../model/Role";
import MyChatBot from "../../pages/chatbot/MyChatBot";
import ChatBots from "../../pages/chatbot/ChatBots";
import MyChatBotChat from "../../pages/chat/MyChatBotChat";
import RequestPasswordReset from "../../pages/login/RequestPasswordReset";
import ResetPassword from "../../pages/login/ResetPassword";
import Profile from "../../pages/profile/Profile";
import CreateChatbot from "../../pages/chatbot/CreateChatbot";
import Success from "../../pages/stripe/Success";
import PriceComponent from "../../pages/stripe/PriceComponent";
import Impressum from "../../pages/home/Impressum";
import IFrameBotIntegration from "../thirdPartyIntegration/IFrameBotIntegration";
import Docs from "../../pages/home/homepage/Docs";
import Showroom from "../../pages/chatbot/Showroom";

export interface MyRoute {
    path: string
    name?: string
    child?: JSX.Element
    showContentOnly?: boolean
    protected?: boolean
    roles?: Role[]
}

export const appRoutes: (MyRoute)[] = [
    {
        path: "/",
        name: "Landing Page",
        child: <Home />,
    },
    {
        path: "/impressum",
        name: "Impressum",
        child: <Impressum />,
    },
    {
        path: "/login",
        name: "Login",
        child: <Login />,
    },
    {
        path: "/docs",
        name: "Docs",
        child: <Docs />,
    },
    {
        path: "/register",
        name: "Register",
        child: <Signup />,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        child: <Dashboard />,
        protected: true,
    },
    {
        path: "/chatBots",
        name: "My Chatbots",
        child: <ChatBots />,
        protected: true,
    },
    {
        path: "/bot/:botId/chat/undecorated",
        name: "Chat with bot",
        child: <MyChatBotChat />,
        showContentOnly: true,
    },
    {
        path: "/bot/:botId/chat/iframe/undecorated",
        name: "Chat with bot",
        child: <IFrameBotIntegration />,
        showContentOnly: true,
    },
    {
        path: "/bot/:botId/chat",
        name: "Chat with bot",
        child: <MyChatBotChat />,
    },
    {
        path: "/bot/:botId",
        name: "Chatbot settings",
        child: <MyChatBot />,
        protected: true,
    },
    {
        path: "/create-bot",
        name: "Create new Bot",
        child: <CreateChatbot />,
        protected: true,
    },
    {
        path: "/profile",
        name: "Profile",
        child: <Profile />,
        protected: true,
    },
    {
        path: "/showroom",
        name: "Showroom",
        child: <Showroom />,
        protected: true,
    },
    {
        path: "/reset-password",
        name: "Reset Password",
        child: <RequestPasswordReset />,
    },
    {
        path: "/reset-password/:processId",
        name: "Reset Password",
        child: <ResetPassword />,
    },
    {
        path: "/success",
        name: "Success",
        child: <Success />
    },
    {
        path: "/pricing",
        name: "Pricing",
        child: <PriceComponent />
    }
   
]