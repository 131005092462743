import React, {ReactNode, useCallback, useMemo, useState} from "react";
import Chats from "../chats/Chats";
import {useLocation, useNavigate} from 'react-router-dom';
import { ChatContainerStyles, ChatContainerStylesUndecorated } from "./ChatbotStyles";
import {
    Alert,
    Avatar,
    Box,
    Chip,
    Grid,
    IconButton,
    Link,
    Tooltip,
    Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
// import Dictaphone1 from "../Dictaphone";
import { useSpeechRecognition } from "react-speech-recognition";
import { ChatResponse, PageContent } from "../../../model/ChatResponse"; 
import i18n from "i18next";
import {ChatbotSettings, Embeddings, FeedbackContent} from "../../../model/Chatbot";
import { useChatbotApiClient } from "../../../clients/ChatbotApiClient";
import { IoCloseSharp, IoSettingsOutline } from "react-icons/io5";
import ChatSettingsModal from "./ChatSettingsModal";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {useHelperBotContext} from "../../../shared/contexts/HelperBotContext";
import {useTranslation} from "react-i18next";
import {cleanLinks} from "../../../shared/tools/StringTools";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import LanguageSwitcher from "../../../shared/components/LanguageSwitcher";
import { IoIosInformationCircleOutline, IoIosSend } from "react-icons/io";
import {useUserContext} from "../../../shared/contexts/UserContext";
import {funnyW8Messages} from "../../../shared/components/RandomFunnyLoadingMessages";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {PiChartLineUpLight,
    PiBird,
    PiButterfly,
    PiCrown,
    PiMoonStars,
    PiGraduationCapLight
} from "react-icons/pi";
import { TbMessageChatbot } from "react-icons/tb";
import {BsEmojiLaughing} from "react-icons/bs";
import {GiTiedScroll} from "react-icons/gi";
import {RxRocket} from "react-icons/rx";
import {HiOutlineHandThumbUp} from "react-icons/hi2";
import {HiOutlineKey} from "react-icons/hi";
import HtmlRenderer from "../../../shared/components/HTMLRenderer";
import UploadFileModal from "../../chatbot/components/UploadFileModal";
import {RiChatDownloadLine, RiChatUploadLine} from "react-icons/ri";
import PulsatingButton from "../../../shared/components/PulsatingButton";
import SpeechBubble from "../../../shared/components/SpeechBubble";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

interface ChatProps {
    nlpTask: string
    nlpModel?: string | null 
    botName: string
    botDescription: string
    botId: string
    undecorated?: boolean
    questionToAsk?: string
    botTopics?: string[]
    botSettings?: ChatbotSettings
}


export interface ChatMessage {
    purpose: string;
    message: string;
    options?: string[];
    sender: string;
    timestamp: string;
    sources: Array<PageContent>;
    best_sources: string;
    cost: number;
    query_about: string;
}

interface AvatarOption {
    icon: ReactNode;
    name: string;
    descr: string;
    prompt: string;
}

const avatarOptions = [
    {icon: <IoIosSend />, name: 'Standard', descr: 'Formell und höflich', prompt: 'Verhalte dich formell, höflich und empatisch. Nutze eine respektvolle Ansprache und höfliche Formulierungen.'},
    {icon: <PiButterfly />, name: 'Elton John', descr: 'Umgangssprachlich und locker', prompt: 'Verhalte dich wie Elton John, umgangssprachlich und locker. Sprich die Nutzer in einem entspannten, freundlichen Ton an.'},
    {icon: <BsEmojiLaughing />, name: 'Robin Williams', descr: 'Humorvoll und witzig', prompt: 'Verhalte dich wie Robin Williams, humorvoll und witzig. Nutze humorvolle, unterhaltsame Formulierungen, um den Nutzern ein Lächeln zu schenken.'},
    {icon: <PiBird />, name: 'Ulmer Schwabe', descr: 'Schwäbisch und Bodenständig', prompt: 'Sprich im Schwäbischen Dialekt. Nutze typische Ausdrücke und eine freundliche, bodenständige Art.'},
    {icon: <PiChartLineUpLight />, name: 'Billie Eilish', descr: 'Jugendlich und trendy', prompt: 'Verhalte dich wie Billie Eilish, jugendlich und trendy. Nutze moderne, coole Ausdrücke und eine lockere Ansprache.'},
    {icon: <PiCrown />, name: 'Johann Wolfgang von Goethe', descr: 'Historisch und altmodisch', prompt: 'Verhalte dich wie Johann Wolfgang von Goethe, historisch und altmodisch. Nutze gehobene Sprache und förmliche Ansprache.'},
    {icon: <RxRocket />, name: 'Elon Musk', descr: 'Technisch und nerdig', prompt: 'Verhalte dich wie Elon Musk, technisch und nerdig. Nutze technische Begriffe und eine geekige Ansprache.'},
    {icon: <GiTiedScroll />, name: 'William Shakespeare', descr: 'Lyrisch und poetisch', prompt: 'Verhalte dich wie William Shakespeare, lyrisch und poetisch. Nutze poetische Formulierungen und eine kreative Ansprache.'},
    {icon: <HiOutlineHandThumbUp />, name: 'Oprah Winfrey', descr: 'Motivierend und inspirierend', prompt: 'Verhalte dich wie Oprah Winfrey, motivierend und inspirierend. Nutze aufmunternde und positive Formulierungen, um die Nutzer zu ermutigen.'},
    {icon: <PiMoonStars />, name: 'Nostradamus', descr: 'Mystisch und geheimnisvoll', prompt: 'Verhalte dich wie Nostradamus, mystisch und geheimnisvoll. Nutze geheimnisvolle und rätselhafte Formulierungen, um eine mystische Atmosphäre zu schaffen.'},
    {icon: <HiOutlineKey />, name: 'Yoda', descr: 'Weise und kryptisch', prompt: 'Verhalte dich wie Yoda aus Star Wars, weise und kryptisch. Nutze eine umgekehrte Satzstruktur und weise, rätselhafte Formulierungen.'},
];

const getAnswerLanguage = (lang: string): string => {
    if (lang === "de") {
        return "Deutsch"
    } else if (lang === "en") {
        return "English"
    } else if (lang === "tr") {
        return "Türkçe"
    } else if (lang === "ua") {
        return "українська"
    } else if (lang === "ar") {
        return "العربية"
    } else if (lang === "hr") { // Kroatisch
        return "Hrvatski"
    } else if (lang === "ro") { // Rumänisch 
        return "Română"
    } else if (lang === "sq") { // Albanisch
        return "Shqip"
    } else if (lang === "sr") { // Serbisch
        return "Српски"
    } else if (lang === "hu") { // Ungarisch
        return "Magyar"
    } else if (lang === "pl") { // Polnisch
        return "Polski"
    } else if (lang === "ru") { // Russisch
        return "Русский"
    } else if (lang === "mk") { // Mazedonisch
        return "Македонски"
    } else if (lang === "es") { // Spanisch
        return "Español"
    } else if (lang === "fr") { // Französisch
        return "Français"
    } else if (lang === "it") { // Italienisch
        return "Italiano"
    } else if (lang === "ja") { // Japanisch
        return "日本語"
    } else if (lang === "el") { // Griechisch 
        return "Ελληνικά"
    } else {
        return "English"
    }
}

const emptyMessage = {
    purpose: "introduction",
    message: "...",
    sender: "bot",
    timestamp: new Date().toLocaleString(),
    sources: new Array<PageContent>(),
    best_sources: "",
    cost: 0,
    query_about: "",
}


const Chat: React.FC<ChatProps> = (props: ChatProps) => {

    const chatbotClient = useChatbotApiClient({ preventNotification: true })
    const userCtx = useUserContext()
    const theme = useTheme()
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation()
    const { transcript } = useSpeechRecognition()
    // eslint-disable-next-line
    const {questionToAsk, setQuestionToAsk } = useHelperBotContext()
    // const isMobile = useCheckMobileScreen()

    const defaultSysPrompt_en = `You are a helpful, respectful and honest assistant. Your name is '{bot.name}'.`
    const defaultSysPrompt_de = `Du bist ein hilfreicher, respektvoller und ehrlicher Assistent. Dein Name ist '{bot.name}'.`
    
    // const promt_en = `Use the following pieces of context to answer the question at the end. {context} Question: {question}`
    // const promt_de = `Verwende die folgenden Kontextinformationen, um die Frage am Ende prägnant zu beantworten. {context} Frage: {question}`
    // const promt_en = `Use the following pieces of context to answer the question at the end. {context} Question: {question} . Answer in english only. Answer in as few sentences as possible.`
    // const promt_de = `Gib deine Antworten ausschließlich in Deutsch. Antworte in so wenigen Sätzen wie möglich. Verwende die folgenden Kontextinformationen, um die Frage am Ende zu beantworten. {context}. Frage: {question} .`

    const preparePrompt = (input: string) => {
        return input.replaceAll("{{bot.name}}", props.botName).replaceAll("{{botName}}", props.botName)
    }
    
    console.log(".. " + (props.nlpModel || props.botSettings?.nlpModel || "OpenAI/gpt-4o"))

    // Chat settings
    const [botTitleText, setBotTitleText] = useState<string>(props.botName)
    const [botIcon, setBotIcon] = useState<string>("/img/bb_solo_small.png")
    const [botColors, setBotColors] = useState<{ [key: string]: string }>({
        primary: '#557A46',
        light: '#F2EE9D',
        timestamp: '#858585',
        bot: '#C7C7C7',
        botText: '#000', 
    })
    const [w8Messages, setW8Messages] = useState<string[]>(funnyW8Messages)
    const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false)
    const [embeddings, setEmbeddings] = useState<Embeddings>(Embeddings.OPEN_AI_TE_3_LARGE)
    const [chatHistoryFromServer, setChatHistoryFromServer] = useState<string>("[]")
    const [nlpModel, setNlpModel] = useState<string>((props.nlpModel || props.botSettings?.nlpModel || "OpenAI/gpt-4o")) // "OpenAI/gpt-3.5-turbo" "OpenAI/gpt-4o"  "meta/llama-2-70b-chat"
    const [nlpTask, setNlpTask] = useState<string>(props.nlpTask) // "RAG" - "CIS"
    const [maxNewTokens, setMaxNewTokens] = useState<number>(props.botSettings?.maxNewTokens || 1500)
    const [temperature, setTemperature] = useState<number>(props.botSettings?.temperature || 0.01)
    const [fetchSimilarDocs, setFetchSimilarDocs] = useState<number>(props.botSettings?.chunksToFetch || 5)
    const [defaultSysPromt, setDefaultSysPromt] = useState<string>(preparePrompt(props.botSettings?.defaultSystemPromptEn || defaultSysPrompt_en))
    // unused
    const [maxLen, setMaxLen] = useState<number>(1500)
    const [topP, setTopP] = useState<number>(0.9)
    const [similarityScoreThreshold, setSimilarityScoreThreshold] = useState<number>(0.2)
    // const [promt, setPromt] = useState<string>(promt_en)
    
    const [autoReadResults, setAutoReadResults] = useState<boolean>(false)
    const [checkAnswers, setCheckAnswers] = useState<boolean>(props.botSettings?.checkAnswers || false)
    const [addSources, setAddSources] = useState<boolean>(props.botSettings?.addSources || false)
    const [base64_pdf, setBase64_pdf] = useState<string>("")
    const [streamAnswer, setStreamAnswer] = useState<string>("stream: ")
    const [infoScreen, setInfoScreen] = useState<boolean>(false)

    const [selctedTopic, setSelctedTopic] = useState<string>("")

    const [addFileModalOpen, setAddFileModalOpen] = useState(false)
 
    // Text-to-speech:
    const utterThis = useMemo(() => new SpeechSynthesisUtterance(), [])
    // utterThis.lang = "en-US";
    utterThis.lang = i18n.language
    const [easyLangEnabled, setEasyLangEnabled] = React.useState<boolean>(false)

    const [showWaitForResponse, setShowWaitForResponse] = useState<boolean>(false);
    const [userResponse, setUserResponse] = useState<string>("");
    const [questionFromOutside, setQuestionFromOutside] = useState<string>("");
    const [messages, setMessages] = useState<ChatMessage[]>([emptyMessage])
    const [pageError, setPageError] = useState<string>()

    // avatar select ("personality")
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedAvatar, setSelectedAvatar] = useState<AvatarOption>({ icon: botIcon, name: '', descr: '', prompt: '' });

    // "hallucination" warning bubble
    const [showBubble, setShowBubble] = useState(true);
    // Feedback-message
    const [awaitUserFeedback, setAwaitUserFeedback] = useState(false);

    const [userGaveConsent, setUserGaveConsent] = useState<boolean>(false); // Initially, user must agree to process data

    const handleDeleteConsent = () => {
        setUserGaveConsent(true);
    };

    const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAvatarClose = () => {
        setAnchorEl(null);
    };

    const handleAvatarMenuItemClick = (option: AvatarOption) => {
        if (option.name === "Standard") {
            setTemperature(props.botSettings?.temperature || 0.01)
            setSelectedAvatar({ icon: botIcon, name: '', descr: '', prompt: '' })
        } else {
            setTemperature(0.5)
            setSelectedAvatar(option);
        }
        handleAvatarClose();
    };

    const handleCloseBubble = () => {
        setShowBubble(false);
    };

    React.useEffect(() => {
        setMessages(getWelcomeMessages(i18n.language))
        // eslint-disable-next-line
    }, []);
    
    const speechHandler = useCallback((msg: string, autoReadResults: boolean) => {
        console.log(autoReadResults)
        console.log(msg)
        if (!autoReadResults) {
            console.log("Dont auto-read results")
            return
        } else {
            utterThis.text = msg
            window.speechSynthesis.speak(utterThis)    
        }
    }, [utterThis])

    React.useEffect(() => {
        setUserResponse(transcript)
    }, [transcript]);

    React.useEffect(() => {
        console.log("Lang is: " + i18n.language)
        setMessages(getWelcomeMessages(i18n.language))
        if (i18n.language === "de") {
            // setPromt(promt_de)
            setDefaultSysPromt(preparePrompt(props.botSettings?.defaultSystemPromptDe || defaultSysPrompt_de))
        } else {
            // setPromt(promt_en)
            setDefaultSysPromt(preparePrompt(props.botSettings?.defaultSystemPromptEn || defaultSysPrompt_en))
        }
        // eslint-disable-next-line
    }, [i18n.language, props.botSettings?.defaultSystemPromptDe, props.botSettings?.defaultSystemPromptEn]);
    
    React.useEffect(()=>{
        setNlpTask(props.nlpTask)
    }, [props.nlpTask])
    
    React.useEffect(()=>{
        if (props.botSettings?.nlpModel) {
            setNlpModel(props.botSettings?.nlpModel)
        }
    }, [props.botSettings?.nlpModel])
    
    React.useEffect(()=>{
        if (props.nlpModel ) {
            setNlpModel(props.nlpModel )
        }
    }, [props.nlpModel])
    
    React.useEffect(()=>{
        if (props.botSettings?.temperature) {
            setTemperature(props.botSettings?.temperature)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.temperature])
    
    React.useEffect(()=>{
        if (props.botSettings?.titleText) {
            setBotTitleText(preparePrompt(props.botSettings?.titleText))    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.titleText])
    
    React.useEffect(()=>{
        if (props.botSettings?.icon) {
            setBotIcon(props.botSettings?.icon)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.icon])
    
    React.useEffect(()=>{
        if (props.botSettings?.colors) {
            setBotColors(props.botSettings?.colors)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.colors])
    
    React.useEffect(()=>{
        if (props.botSettings?.w8messages && props.botSettings?.w8messages.length > 0) {
            setW8Messages(props.botSettings?.w8messages)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.w8messages])
    
    React.useEffect(()=>{
        if (props.botSettings?.chunksToFetch) {
            setFetchSimilarDocs(props.botSettings?.chunksToFetch)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.chunksToFetch])
    
    React.useEffect(()=>{
        if (props.botSettings?.addSources) {
            setAddSources(props.botSettings?.addSources)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.addSources])
    
    React.useEffect(()=>{
        if (props.botSettings?.checkAnswers) {
            setCheckAnswers(props.botSettings?.checkAnswers)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.checkAnswers])
    
    React.useEffect(()=>{
        if (props.botSettings?.maxNewTokens) {
            setMaxNewTokens(props.botSettings?.maxNewTokens)    
        }
        // eslint-disable-next-line
    }, [props.botSettings?.maxNewTokens])
    
    React.useEffect(()=>{
        setMessages(getWelcomeMessages(i18n.language))
        // eslint-disable-next-line
    }, [nlpTask])

    React.useEffect(() => {
        if (props.questionToAsk && props.questionToAsk.length > 0) {
            setUserResponse(props.questionToAsk)
            setQuestionFromOutside(props.questionToAsk)
        }
    }, [props.questionToAsk])

    React.useEffect(() => {
        if (questionFromOutside && questionFromOutside.length > 0) {
            handleSubmit(questionFromOutside, (awaitUserFeedback ? "Feedback-3-send" : ""))
        }
        // eslint-disable-next-line
    }, [questionFromOutside]);

    React.useEffect(() => {
        if (messages.length === 1) {
            speechHandler(messages[0].message, autoReadResults)
        }
    }, [messages, speechHandler, autoReadResults])

    React.useEffect(() => {
        console.log("autoReadResults changed to " + autoReadResults)
    }, [autoReadResults])

    const optionClick = (e: React.MouseEvent<HTMLElement>) => {
        let option = e.currentTarget.dataset.id;
        if (option) {
            // setNextStep(option);
        }
    };

    const getWelcomeMessages = (lang: string): ChatMessage[] => {
        let welcomeMsgs = ["Hi, I'm {{botName}}!"]
        if (props.botSettings) {
            if (nlpTask === "RAG" && props.botSettings.welcome_rag) {
                if (lang in props.botSettings.welcome_rag) {
                    welcomeMsgs = props.botSettings.welcome_rag[lang]
                } 
            } else if (nlpTask === "CIS" && props.botSettings.welcome_cis) {
                if (lang in props.botSettings.welcome_cis) {
                    welcomeMsgs = props.botSettings.welcome_cis[lang]
                }
            }
        }

        const initMsgs: ChatMessage[] = []
        welcomeMsgs.forEach((msg: string) => {
            initMsgs.push({
                purpose: "introduction",
                message: preparePrompt(msg),
                sender: "bot",
                timestamp: new Date().toLocaleString(),
                sources: new Array<PageContent>(),
                best_sources: "",
                cost: 0,
                query_about: "",
            })
        })
        
        return initMsgs;
    }
    
    // event handlers
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserResponse(e.target.value);
    };

    const addUserKnowledgeToBot = () => {
        chatbotClient.addUserKnowledgeToBot(props.botId)
    }
    
    const navTo = (targetNlpTask: string) : void => {
        // Parse the current query parameters
        const searchParams = new URLSearchParams(location.search);

        // Add or update a query parameter, e.g., set `exampleParam` to `newValue`
        searchParams.set('nlpTask', targetNlpTask);

        // Construct the new URL
        const newUrl = `${location.pathname}?${searchParams.toString()}`;

        // Navigate to the new URL
        navigate(newUrl);
    }

    const handleSubmit = (theQuestion: string, extraParam?: string) => {
        setPageError(undefined)
        setStreamAnswer("")

        if (extraParam === "Feedback-3-send") {
            theQuestion = userResponse
        }

        let userConfirmedPersonalDataProcessing = false
        if (extraParam === "agree_sending_personal_data") {
            userConfirmedPersonalDataProcessing = true
        }                

        if (theQuestion.length === 0) {
            return
        }

        setMessages(messages => [...messages, {
            purpose: "",
            message: (selctedTopic.length > 0 ? selctedTopic + ": " : "") + theQuestion,
            sender: "user",
            timestamp: new Date().toLocaleString(),
            sources: new Array<PageContent>(),
            best_sources: "",
            cost: 0,
            query_about: ""
        }])

        if (extraParam === "dont_send_personal_data") {
            setUserResponse("");
            const res = {
                purpose: "res",
                message: preparePrompt(
                    "In Ordnung."),
                sources: [],
                best_sources: "",
                cost: 0,
                query_about: "",
            }
            speechHandler(res.message, autoReadResults)
            setStreamAnswer("")
            setMessages(messages => [...messages, { ...res,
                sender: "bot",
                timestamp: new Date().toLocaleString()
            }])
            setShowWaitForResponse(false)
            return
        }
        
        if (extraParam === "Feedback-1") {
            setUserResponse("");
            const res = {
                purpose: "res",
                message: preparePrompt(
                    "Sicherlich. Das Team freut sich über Lob, konstruktive Kritik und Verbessungsvorschlage. " +
                    "Schreibe dein Anliegen gerne unten ein und drücke anschließend auf 'Feedback senden'. " +
                    "Anschließend wird deine Eingabe und der komplette Chat-Verlauf an das KI-Team geschickt. " +
                    "Wenn du das nicht möchtest, kannst du das Chat-Fenster jetzt schließen und keine Daten werden gesendet."),
                sources: [],
                best_sources: "",
                cost: 0,
                query_about: "Feedback-2",
            }
            setAwaitUserFeedback(true)
            speechHandler(res.message, autoReadResults)
            setStreamAnswer("")
            setMessages(messages => [...messages, { ...res,
                sender: "bot",
                timestamp: new Date().toLocaleString()
            }])
            setShowWaitForResponse(false)
            
            return
        } else if (extraParam === "Feedback-3-send") {
            setAwaitUserFeedback(false)
            setUserResponse("");
            const res = {
                purpose: "res",
                message: preparePrompt(
                    "Vielen Dank, wir haben das Feedback an das Team gesendet."),
                sources: [],
                best_sources: "",
                cost: 0,
                query_about: "Feedback-4",
            }
            speechHandler(res.message, autoReadResults)
            setStreamAnswer("")
            setMessages(messages => [...messages, { ...res,
                sender: "bot",
                timestamp: new Date().toLocaleString()
            }])
            setShowWaitForResponse(false)
            
            const pdfstring = base64_pdf
            // const feedback = messages[messages.length - 2].message
            const feedback = userResponse

            const dateTime = new Date(); // current date and time
            const dateTimeISO = dateTime.toISOString(); // "2024-08-22T13:47:23.000Z"
            
            const feedbackContent: FeedbackContent = {
                createdAt: dateTimeISO,
                feedback: feedback,
                chatPdfAsBase64: pdfstring
            }

            chatbotClient.giveFeedback(props.botId, feedbackContent)
            
            return
        }

        setTimeout(() => {
            setShowWaitForResponse(true)
        }, 200);

        

        const chatRequest = {
            // embeddings: embeddings, // Embeddings.SENTENCE_TRANSFORMERS_ALL_MINILM_L6_V2
            question: theQuestion + " " + (easyLangEnabled ? " Antworte in leichter Sprache." : ""), // + (i18n.language === "de" ? "Antworte ausschließlich auf Deutsch." : "Answer in english only."), // "Can I use the ULTRIS camera while being on an airplane?",
            userConfirmedPersonalDataProcessing: userConfirmedPersonalDataProcessing,
            // nlpModel: nlpModel, // "meta/llama-2-70b-chat" // "meta/llama-2-7b-chat"
            // temperature: temperature,
            // top_p: topP,
            // max_length: maxLen, // overal size (tokens) for input + output
            // max_new_tokens: maxNewTokens, // The size of the output sequence.
            // repetition_penalty: 1.15,
            // default_sys_prompt: defaultSysPromt,
            // prompt: "",
            answerLanguage: getAnswerLanguage(i18n.language),
            // fetchSimilarDocs: fetchSimilarDocs,
            // topic: selctedTopic,
            // similarityScoreThreshold: similarityScoreThreshold,
            history: chatHistoryFromServer,
            chat_type: nlpTask,
            // check_answers: checkAnswers,
            // add_sources: addSources,
            extra_param: (extraParam ? extraParam : ""),
            personality_prompt: selectedAvatar.prompt,
        }

        chatbotClient.chatWithChatbot(props.botId, chatRequest,
            (chunk: string, event_type: string) => {
                if (event_type === "answer_chunk") {
                    setStreamAnswer(prevStreamAnswer => prevStreamAnswer + chunk)    
                }
            },
            (lastChunkReceived: string) => {
                // console.log(lastChunkReceived)
                const fullAnswerObj = lastChunkReceived                
                let res = {
                    purpose: "res",
                    message: "",
                    sources: [{
                        page_content: "",
                        metadata: {
                            source: "",
                            page: 0,
                            topic: "",
                            similarity_score: 0,
                            store: "",
                        }
                    }],
                    best_sources: "",
                    cost: 0,
                    query_about: ""
                }
                if (lastChunkReceived.includes("Personal data detected. Are you sure you want to proceed?")) {
                    res["message"] = "⚠ Es scheint, als ob du eine Anfrage mit personenbezogene Daten absetzen möchtest.\n" +
                        "Bist du sicher?"
                    res["query_about"] = "ask_sending_personal_data " + chatRequest.question
                } else if (lastChunkReceived.includes("Access denied")) {
                    res["message"] = "🚫 Access denied"
                    res["query_about"] = ""
                } else {
                    const response = JSON.parse(fullAnswerObj) as ChatResponse;
                    setChatHistoryFromServer(response.history)
                    setBase64_pdf(response.history_as_pdf)
                    if (response.source_documents !== undefined) {
                        response.source_documents.forEach(pageContent => {
                            pageContent.metadata.source = cleanLinks(pageContent.metadata.source)
                        })
                    }
                    res = {
                        purpose: "res",
                        message: preparePrompt(response.result),
                        sources: response.source_documents,
                        best_sources: response.best_sources,
                        cost: response.token_usage.costs,
                        query_about: response.query_about,
                    }
                }
               
                speechHandler(res.message, autoReadResults)
                setStreamAnswer("")
                setMessages(messages => [...messages, { ...res,
                    sender: "bot",
                    timestamp: new Date().toLocaleString()
                }])
                setShowWaitForResponse(false)
            }
        )
        /*
        chatbotClient.chatWithChatbot(props.botId, chatRequest)
            .then((response: ChatResponse) => {
                setChatHistoryFromServer(response.history)
                setBase64_pdf(response.history_as_pdf)
                response.source_documents.forEach(pageContent => {
                    pageContent.metadata.source = cleanLinks(pageContent.metadata.source)
                })
                const res = {
                    purpose: "res",
                    message: response.result,
                    sources: response.source_documents,
                    best_sources: response.best_sources,
                }
                speechHandler(res.message, autoReadResults)
                setMessages(messages => [...messages, { ...res, 
                    sender: "bot", 
                    timestamp: new Date().toLocaleString() 
                }])
                

            })
            .catch((e: ErrorResponse) => { setPageError(e.message) })
            .finally(() => { setShowWaitForResponse(false) })
        */
        setUserResponse("");
         

    };

    return (
        <>
            <Box sx={(props.undecorated ? 
                    ({
                        ...ChatContainerStylesUndecorated,
                    }) : 
                    ({
                        ...ChatContainerStyles,
                        border: `0.5px solid ${botColors.primary}`,
                        backgroundColor: "rgba(255,255,255, 0.0)"
                    })
                )} 
                 onKeyDownCapture={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit(userResponse, (awaitUserFeedback ? "Feedback-3-send" : ""))
                    }
                }}
            >
                {/*Header bar */}
                <Grid container justifyContent={"space-between"} alignItems={"center"} 
                    sx={{ 
                        backgroundColor: botColors.primary,
                        border: `0.5px solid ${botColors.primary}`,
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        padding: "8px",
                        color: botColors.light
                    }}
                >
                    <Grid item>
                        <Grid container alignItems={"center"} spacing={3}>
                            <Grid item>
                                <IconButton onClick={handleAvatarClick}>
                                    <Avatar alt={"bot"} src={botIcon} />                                    
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleAvatarClose}
                                >
                                    {avatarOptions.map((option) => (
                                        <MenuItem key={option.prompt} onClick={() => handleAvatarMenuItemClick(option)}>
                                            <Avatar style={{ marginRight: 8 }}>
                                                {option.icon}
                                            </Avatar>
                                            <ListItemText
                                                primary={option.name}
                                                secondary={option.descr}
                                            />
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                            <Grid item>
                                {botTitleText}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <PulsatingButton color="inherit" onClick={() => setInfoScreen(!infoScreen)}>
                            {infoScreen ? 
                                <IoCloseSharp size={24} /> :
                                <IoIosInformationCircleOutline size={24} />
                            }
                        </PulsatingButton>
                    </Grid>
                </Grid>
                
                <ChatSettingsModal
                    open={settingsModalOpen}
                    onClose={() => {
                        setSettingsModalOpen(false)
                    }}
                    embeddings={embeddings}
                    setEmbeddings={setEmbeddings}
                    nlpModel={nlpModel} setNlpModel={setNlpModel}
                    nlpTask={nlpTask} setNlpTask={setNlpTask}
                    maxLen={maxLen} setMaxLen={setMaxLen}
                    maxNewTokens={maxNewTokens} setMaxNewTokens={setMaxNewTokens}
                    temperature={temperature} setTemperature={setTemperature}
                    topP={topP} setTopP={setTopP}
                    defaultSysPromt={defaultSysPromt} setDefaultSysPromt={setDefaultSysPromt}
                    fetchSimilarDocs={fetchSimilarDocs} setFetchSimilarDocs={setFetchSimilarDocs}
                    similarityScoreThreshold={similarityScoreThreshold} setSimilarityScoreThreshold={setSimilarityScoreThreshold}
                    speechUtterance={utterThis}
                    autoReadResults={autoReadResults} setAutoReadResults={setAutoReadResults}
                    checkAnswers={checkAnswers} setCheckAnswers={setCheckAnswers}
                    addSources={addSources} setAddSources={setAddSources}
                />
                {pageError &&
                    <Grid container justifyContent='center'>
                        <Alert severity="error">
                            {pageError}
                        </Alert>
                    </Grid>
                }

                {infoScreen ?
                    <Grid container direction="column" sx={{
                        width: '100%', 
                        height: "100%", 
                        background: 'white', 
                        padding: "1.5em",
                        justifyContent: 'space-between',
                        backgroundColor: "rgba(255,255,255, 0.9)",
                        overflowY: 'auto',
                    }}>
                        <Grid item xs={11}>
                            <Typography fontSize={"10px"}>
                                {"Dieser Chatbot wurde erstellt durch "}
                                <Link href="https://youniquehorns.com/" variant="inherit" target="_blank">
                                    YOUniquehorns GmbH
                                </Link>
                            </Typography><br/>
                            <Typography>
                                <HtmlRenderer htmlString={(props.botSettings && props.botSettings.descriptions ? props.botSettings.descriptions['de'][0] : "")} />
                            </Typography>
                        </Grid>
                    </Grid>
                : 
                    <Chats
                        showWaitForResponse={showWaitForResponse}
                        optionClick={optionClick}
                        messages={messages}
                        streamAnswer={streamAnswer}
                        undecorated={props.undecorated}
                        speechUtterance={utterThis}
                        botName={props.botName}
                        nlpTask={nlpTask}
                        chatWithBot={handleSubmit}
                        botColors={botColors}
                        w8Messages={w8Messages}
                    />
                }

                <Box sx={{backgroundColor: "rgba(255,255,255, 0)",}}>
                    {/* Topics */}
                    {props.botTopics && props.botTopics.length > 0 && 
                        <ListItem sx={{ paddingLeft: "55px", paddingRight: "0px", color: "gray", fontSize: '12px', backgroundColor: "rgba(255,255,255, 0.9)" }}>
                            {t('chat.topic')}
                            <IconButton size={"small"} sx={{margin: "0px", padding: "0 0 7px 0", color: theme.palette.primary.main}}
                                onClick={()=>setQuestionToAsk(t("chat.help.whatAreTopicsGoodFor"))}
                            >
                                <HelpOutlineOutlinedIcon sx={{width: "12px"}}/>
                            </IconButton> :
                            {props.botTopics.map(topic => {
                                const isSelected = (topic === selctedTopic)    
                                return (<>
                                <Chip label={topic} size="small" 
                                      sx={{margin: "0 3px 0 3px"}} variant={(isSelected ? "filled" : "outlined")}
                                      onClick={()=>{
                                          if (isSelected) {
                                              setSelctedTopic("")
                                          } else {
                                              setSelctedTopic(topic)    
                                          }
                                      }}
                                />
                                </>)
                            })}
                        </ListItem>
                    }
                    {/* Don't share personal data info */}
                    <Box
                        sx={{
                            fontSize: "8px", textAlign: "center", color: "gray", paddingTop: "0px",
                            marginLeft: "auto", marginRight: "auto", display: "flex",
                            justifyContent: "center", alignItems: "center", border: "none", boxShadow: "none", backgroundColor: "rgba(255,255,255, 0.9)"
                        }}
                    >
                        {t("chat.dontSharePersonalData")}
                    </Box>
                    
                    {/* Input & Settings */}
                    <ListItem alignItems="flex-start" sx={{ padding: "0px", backgroundColor: "rgba(255,255,255, 0)" }}>
                        <Paper
                            component="form"
                            sx={{ 
                                p: '8px 8px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                width: "100%", 
                                borderBottomLeftRadius: "10px",
                                backgroundColor: "white", 
                                borderBottomRightRadius: "10px", 
                                borderTopRightRadius: "0px", 
                                borderTopLeftRadius: "0px", 
                                borderTop: `1px solid ${botColors.primary}` 
                            }}
                        >
                            <LanguageSwitcher
                                easyLangEnabled={easyLangEnabled}
                                setEasyLangEnabled={setEasyLangEnabled}
                            />
                            {!userGaveConsent ? (
                                <Chip
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            Einverstanden mit &nbsp;
                                                <span
                                                    onClick={() => {
                                                        if (props.botId === 'a2ed0962-0cf6-44f4-9f1c-46f596dee46b') {
                                                            window.open("https://www.ulm.de/sonderseiten/datenschutzerklaerung#Verwendung_Chatbot", '_blank');
                                                        } else {
                                                            setInfoScreen(!infoScreen)
                                                        }
                                                    }} 
                                                    style={{
                                                        cursor: 'pointer',
                                                        textDecoration: 'underline',
                                                        color: '#3f51b5', // Optional: specify the color directly
                                                    }}
                                                >
                                                    Datenverarbeitung
                                                </span>{' '}
                                            <IconButton size="small" onClick={() => {}}>
                                                <CloseIcon />
                                            </IconButton>
                                            <IconButton size="small" onClick={handleDeleteConsent}>
                                                <CheckIcon />
                                            </IconButton>
                                        </Box>
                                    }
                                    sx={{ ml: 1 }}
                                />
                            ) : (!awaitUserFeedback && messages.length > 16 && props.botId === 'a2ed0962-0cf6-44f4-9f1c-46f596dee46b' ?
                                (
                                    <Chip
                                        label={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                Zu viele Anfragen, bitte neu starten.
                                            </Box>
                                        }
                                        sx={{ ml: 1 }}
                                    />
                                )
                                : (
                                    <>
                                        {/*<Dictaphone1 />*/}
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder={t("chat.userInput")}
                                            inputProps={{ 'aria-label': 'user input' }}
                                            onChange={e => handleInputChange(e)}
                                            value={userResponse}
                                        />
                                        <IconButton sx={{color: botColors.primary}} onClick={
                                            () => handleSubmit(userResponse, (awaitUserFeedback ? "Feedback-3-send" : ""))
                                        }>
                                            { selectedAvatar.name !== "" ? selectedAvatar.icon : <IoIosSend />}
                                        </IconButton>
                                    </>
                                )
                            )}
                            

                            {/* Halucination warning */}
                            {props.botId === 'a2ed0962-0cf6-44f4-9f1c-46f596dee46b' && showBubble && userGaveConsent && (
                                <Box sx={{ position: 'relative', display: 'inline-block', marginTop: '20px' }}>
                                    <SpeechBubble
                                        text="Ich weiß viel, kann mich aber leider auch irren. <br/>Prüfe wichtige Informationen!"
                                        onClose={handleCloseBubble}
                                        position="bottom-right" // Change position as needed
                                    />
                                </Box>
                            )}

                            {/* Send button */}
                            {!props.undecorated && 
                                <>
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    
                                    {(!props.botName.startsWith("Theresia") || userCtx.isLoggedIn()) &&
                                        <IconButton onClick={() => setSettingsModalOpen(true)}>
                                            <IoSettingsOutline />
                                        </IconButton>
                                    }  
                                </>
                            }
                            
                            {/* switch between nlp-tasks */}
                            {props.botId !== 'a2ed0962-0cf6-44f4-9f1c-46f596dee46b' && nlpTask.startsWith('RAG') && 
                                <>
                                    <Tooltip title={"Teach the bot something!"}>
                                        <IconButton onClick={() => navTo("CIS")}>
                                            <PiGraduationCapLight />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            {props.botId !== 'a2ed0962-0cf6-44f4-9f1c-46f596dee46b' && nlpTask.startsWith('CIS') &&
                                <>
                                    <Tooltip title={"Upload something"}>
                                        <IconButton onClick={() => setAddFileModalOpen(true)}>
                                            <RiChatUploadLine />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Ask the bot something!"}>
                                        <IconButton onClick={() => navTo("RAG")}>
                                            <TbMessageChatbot  />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            
                            {/* pdf download */}
                            {base64_pdf && base64_pdf.length > 0 && false &&
                                <>
                                    <a download="chat.pdf" href={"data:application/pdf;base64," + base64_pdf} >
                                        <IconButton >
                                            <RiChatDownloadLine />
                                        </IconButton>
                                    </a>
                                    
                                    
                                </>
                            }
                        </Paper>
                    </ListItem>

                    {/* Upload dialog for "Teach me something!" */}
                    <UploadFileModal
                        open={addFileModalOpen}
                        subfolder={'userInput'}
                        onClose={() => {
                            setAddFileModalOpen(false)
                            addUserKnowledgeToBot()
                        }}
                        bot_id={props.botId} 
                    />
                    
                </Box>
            </Box>
        </>
    );
};

export default Chat;
