import * as React from 'react';
import {Box, FormControlLabel, FormGroup, IconButton, Switch, Tooltip} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from "react-i18next";

export interface ILanguageSwitcherProps {
    easyLangEnabled?: boolean
    setEasyLangEnabled?: (en: boolean) => void
}

const LanguageSwitcher: React.FunctionComponent<ILanguageSwitcherProps> = (props: ILanguageSwitcherProps) => {

    // const theme = useTheme();
    const {i18n} = useTranslation();

    const [flagIcon, setFLagIcon] = React.useState<string>("/img/locale/US_16x16.png")
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null)
    const [easyLangEnabled, setEasyLangEnabled] = React.useState<boolean>(props.easyLangEnabled || false)

    React.useEffect(() => {
        setFLagIcon(getFlagIconFromLocal(i18n.language))
    }, [i18n.language])

    const handleMenu2 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget)
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const changeLang = (lang: string) => {
        i18n.changeLanguage(lang)
        setFLagIcon(getFlagIconFromLocal(lang))
        handleClose2()
    }
    
    const toggleEasyLang = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEasyLangEnabled(event.target.checked)
        if (props.setEasyLangEnabled !== undefined) {
            props.setEasyLangEnabled(event.target.checked)    
        }
    }

    // https://github.com/lipis/flag-icons/tree/main/flags/4x3
    const getFlagIconFromLocal = (language: string): string => {
        if (language === "de") {
            return "/img/locale/DE_16x16.png"
        } else if (language === "en") {
            return "/img/locale/US_16x16.png"
        } else if (language === "tr") {
            return "/img/locale/tr.svg"
        }  else if (language === "uk") {
            return "/img/locale/ua.svg"
        }  else if (language === "ar") {
            return "/img/locale/sa.svg"
       }  else if (language === "hr") {
            return "/img/locale/hr.svg"
        }  else if (language === "ro") {
            return "/img/locale/ro.svg"
        } else if (language === "sr") {
            return "/img/locale/rs.svg"
        } else if (language === "sq") {
            return "/img/locale/al.svg"
        } else if (language === "hu") {
            return "/img/locale/hu.svg"
        } else if (language === "pl") {
            return "/img/locale/pl.svg"
        } else if (language === "ru") {
            return "/img/locale/ru.svg"
        } else if (language === "mk") {
            return "/img/locale/mk.svg"
        } else if (language === "es") {
            return "/img/locale/es.svg"
        } else if (language === "fr") {
            return "/img/locale/fr.svg"
        } else if (language === "it") {
            return "/img/locale/it.svg"
        } else if (language === "ja") {
            return "/img/locale/jp.svg"
        } else if (language === "el") {
            return "/img/locale/gr.svg"
        } else {
            return "/img/locale/US_16x16.png"
        }
    }

    return (
        <>
            <Tooltip title="Eingabesprache">
                <IconButton size="large" aria-label="" aria-controls="language-appbar2"
                            aria-haspopup="true" onClick={handleMenu2} color="primary">
                    <Box src={flagIcon} component="img" sx={{ width: 16, height: 16 }} />
                </IconButton>
            </Tooltip>
            <Menu id="language-appbar2" anchorEl={anchorEl2}
                  anchorOrigin={{vertical: 'top', horizontal: 'right',}}
                  keepMounted
                  transformOrigin={{vertical: 'top', horizontal: 'right',}}
                  open={Boolean(anchorEl2)}
                  onClose={handleClose2}
            >
                { props.setEasyLangEnabled !== undefined && (props.easyLangEnabled !== undefined) &&
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch onChange={toggleEasyLang} checked={easyLangEnabled} />}
                            label={"Leichte Sprache"}
                        />
                    </FormGroup>
                }
                <MenuItem onClick={() => changeLang("de")}><Box src={"/img/locale/DE_16x16.png"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Deutsch</MenuItem>
                <MenuItem onClick={() => changeLang("en")}><Box src={"/img/locale/US_16x16.png"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;English</MenuItem>
                <MenuItem onClick={() => changeLang("tr")}><Box src={"/img/locale/tr.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Türkçe</MenuItem>
                <MenuItem onClick={() => changeLang("uk")}><Box src={"/img/locale/ua.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;українська</MenuItem>
                <MenuItem onClick={() => changeLang("ar")}><Box src={"/img/locale/sa.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;العربية</MenuItem>
                <MenuItem onClick={() => changeLang("hr")}><Box src={"/img/locale/hr.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Hrvatski</MenuItem>
                <MenuItem onClick={() => changeLang("ro")}><Box src={"/img/locale/ro.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Română</MenuItem>
                <MenuItem onClick={() => changeLang("sq")}><Box src={"/img/locale/al.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Shqip</MenuItem>
                <MenuItem onClick={() => changeLang("sr")}><Box src={"/img/locale/rs.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Српски</MenuItem>
                <MenuItem onClick={() => changeLang("hu")}><Box src={"/img/locale/hu.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Magyar</MenuItem>
                <MenuItem onClick={() => changeLang("pl")}><Box src={"/img/locale/pl.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Polski</MenuItem>
                <MenuItem onClick={() => changeLang("ru")}><Box src={"/img/locale/ru.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Русский</MenuItem>
                <MenuItem onClick={() => changeLang("mk")}><Box src={"/img/locale/mk.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Македонски</MenuItem>
                <MenuItem onClick={() => changeLang("es")}><Box src={"/img/locale/es.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Español</MenuItem>
                <MenuItem onClick={() => changeLang("fr")}><Box src={"/img/locale/fr.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Français</MenuItem>
                <MenuItem onClick={() => changeLang("it")}><Box src={"/img/locale/it.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Italiano</MenuItem>
                <MenuItem onClick={() => changeLang("ja")}><Box src={"/img/locale/jp.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;日本語</MenuItem>
                <MenuItem onClick={() => changeLang("el")}><Box src={"/img/locale/gr.svg"} component="img" sx={{ width: 16, height: 16 }} />&nbsp;Ελληνικά</MenuItem>
            </Menu>
        </>
    )
};

export default LanguageSwitcher;
