import {Box, Grid} from '@mui/material';
import * as React from 'react';
import Chat from "../../pages/chat/chatbot/Chat";
import Typewriter from "../components/Typewriter";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {useChatbotApiClient} from "../../clients/ChatbotApiClient";
import {useCallback, useEffect, useState} from "react";
import {Chatbot} from "../../model/Chatbot";

export interface IIFrameBotIntegrationProps {
    botId?: string;
}

/*
All this contentend is intended to render in an iframe to include the chatbot in any page...
 */
const IFrameBotIntegration: React.FunctionComponent<IIFrameBotIntegrationProps> = (props: IIFrameBotIntegrationProps) => {
    const { t, i18n } = useTranslation()
    const [helperBotOpen, setHelperBotOpen] = React.useState<boolean>(false)
    const [mirrorIFrameIcon, setMirrorIFrameIcon] = React.useState<boolean>(true)
    const [questionToAsk, setQuestionToAsk] = React.useState<string>("")
    const [botId, setBotId] = React.useState<string | null>(null);
    const [bot, setBot] = useState<Chatbot>()
    
    // Get botID from URL
    const location = useLocation();
    const extractBotId = (url: string): string | null => {
        const match = url.match(/\/bot\/([0-9a-fA-F-]{36})\/chat\/iframe\/undecorated/);
        return match ? match[1] : null;
    };
    React.useEffect(() => {
        if (props.botId) {
            setBotId(props.botId)
        } else {
            const extractedBotId = extractBotId(location.pathname);
            setBotId(extractedBotId);    
        }
        // eslint-disable-next-line
    }, [location.pathname]);
    
    const chatbotClient = useChatbotApiClient()
    const fetchChatbot = useCallback(() => {
        if (botId) {
            chatbotClient.getChatbot(botId)
                .then((theBot: Chatbot) => {
                    setBot(theBot)
                    setMirrorIFrameIcon(true)
                    // todo: if (theBot.settings?.mirrorIFrameIcon)
                    // todo: topics?
                })
                .catch((err) => {
                    console.error(err)
                })
        }
    }, [botId, chatbotClient])

    useEffect(() => {
        fetchChatbot()
    }, [fetchChatbot])
    
    
    
    // listen for incomming postMessage events from parent window to the iFrame
    const questionHandler = ((e: MessageEvent) => {
        if (e.data.type !== 'questionToAsk') return
        console.log("questionToAsk: " + e.data.message);
        setHelperBotOpen(true)
        setQuestionToAsk(e.data.message)
    }) as EventListener
    
    React.useEffect(() => {
        window.addEventListener("message", questionHandler);
        return () => window.removeEventListener('message', questionHandler)
        // eslint-disable-next-line
    }, []);
    
    
    // listen for incomming postMessage events from parent window to the iFrame about changed languages
    const langHandler = ((e: MessageEvent) => {
        if (e.data.type !== 'languageChange') return
        console.log("languageChange: " + e.data.message)
        i18n.changeLanguage(e.data.message)
    }) as EventListener
    
    React.useEffect(() => {
        window.addEventListener("message", langHandler);
        return () => window.removeEventListener('message', langHandler)
        // eslint-disable-next-line
    }, []);
    
    // Inform parent about the open/close state of the bot so parent can adjust size of our iFrame
    React.useEffect(() => {
        if (window.parent) {
            console.log("chatbotIsOpen: " + questionToAsk)
            window.parent.postMessage(
                {
                    type: 'chatbotIsOpen',
                    message: helperBotOpen + ""
                },
                '*'
            )
        }
        // eslint-disable-next-line
    }, [helperBotOpen])
    
    // https://neondigitalarts.com/how-to-make-a-gif-using-gimp-software/
    return (
        <>
            <Grid container justifyContent={"right"} spacing={0}
                  sx={{position: "fixed", right: "2px", bottom: "2px", zIndex: 1000, width: "490px"}}
            >
                {helperBotOpen && bot && 
                    <Grid item xs={12} justifyContent='right' sx={{ }}>
                        <Chat 
                            botId={bot.id}
                            botName={bot.name}
                            botDescription={bot.description}
                            botSettings={bot.settings}
                            nlpTask={"RAG"}
                            undecorated={true}
                            questionToAsk={(questionToAsk && questionToAsk.length > 0 ? questionToAsk : undefined )}
                        />
                    </Grid>
                }
    
                {!helperBotOpen && (bot?.settings?.showBookAnimationAboveIFrameIcon ? bot?.settings?.showBookAnimationAboveIFrameIcon : false) &&
                    <Grid item xs={12} container justifyContent='right'>
                        <Box src={"/img/animation/Books4.gif"} component="img"
                             sx={{ width: '50px'}}
                        />
                    </Grid>
                }
    
                {!helperBotOpen && (bot?.settings?.showIFrameHelperText ? bot?.settings?.showIFrameHelperText : false) && 
                    <Grid item xs={11} container justifyContent={'right'} 
                          sx={{paddingTop: (helperBotOpen ? '10px' : '3px'), paddingRight: '15px'}}>
                        <Typewriter
                            text={t("helperBot.iCanHelp")}
                            delay={80}
                            infinite={true}
                        />
                    </Grid>
                }
    
                <Grid item xs={(!helperBotOpen ? 1 : 12)} container justifyContent={'right'} 
                    sx={{paddingTop: (helperBotOpen ? '10px' : '3px'), cursor: 'pointer'}}>
                    <Box src={(bot?.settings?.icon ? bot?.settings?.icon : "/img/bb_solo_small2.png")} component="img"
                         onClick={()=>setHelperBotOpen(!helperBotOpen)}
                         sx={{
                            width: '50px', 
                            transform: mirrorIFrameIcon ? 'scaleX(-1)': 'scaleX(1)'
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default IFrameBotIntegration;