import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    ListItemButton,
    ListItemIcon, Tooltip,
    Typography
} from "@mui/material";
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import {
    messageContainer,
    messageContainerBot,
    messageContainerOptionsP,
    messageContainerUser,
    messageContainerWait
} from "./ChatsStyles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ChatMessage } from "../chatbot/Chat";
import { FaStop, FaPlay } from "react-icons/fa";
import {cleanLinks, decodeUnicode} from "../../../shared/tools/StringTools";
import {NavLink} from "react-router-dom";
import DOMPurify from 'dompurify';
import RandomFunnyLoadingMessages from "../../../shared/components/RandomFunnyLoadingMessages";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import {BiMessageEdit} from "react-icons/bi";
import {PageContent} from "../../../model/ChatResponse";
import {GoStar, GoStarFill} from "react-icons/go";

interface Props {
    messages: ChatMessage[]
    streamAnswer: string
    showWaitForResponse: boolean
    optionClick: (ev: React.MouseEvent<HTMLElement>) => void;
    undecorated?: boolean
    speechUtterance: SpeechSynthesisUtterance
    botName: string
    nlpTask: string
    chatWithBot: (msg: string, extraParam?: string) => void;
    botColors: { [key: string]: string }
    w8Messages: string[]
}

const Chats: React.FC<Props> = props => {
    const dummyRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    const [speaking, setSpeaking] = useState<boolean>(false);
    
    const stopReading = () => {
        window.speechSynthesis.cancel()
        setSpeaking(false)
    }
    
    const readThis = (txt: string) => {
        setSpeaking(true)
        props.speechUtterance.text = txt
        window.speechSynthesis.speak(props.speechUtterance)
    }
    
    const getBestSourcesElements = (best_sources: string) => {
        if (!best_sources || best_sources === "") {
            return (<></>)
        }
        const best_sources_clean = best_sources.replaceAll("<br/>", "").replaceAll("`", "\"");
        const best_sources_obj = JSON.parse(best_sources_clean);
        if (best_sources_obj.length === 0) {
            return (<></>)
        }
        return (<Grid container>
            {best_sources_obj.best_sources.length > 0 && best_sources_obj.best_sources.map((bs: string, i: number) => (
                <Grid item >
                    <Button sx={{ width: '150px', fontSize: '8px', marginRight: '15px', marginTop: '15px', marginBottom: '15px', backgroundColor: props.botColors.primary }} variant="contained" target="_blank" component={NavLink} to={cleanLinks(bs)}>
                        {best_sources_obj.source_description[i]}
                    </Button>
                </Grid>
            ))}
        </Grid>)
    }
    
    const getSourceIndicatorWithStars = (pcs: Array<PageContent>) => {

        if (pcs.length === 0 || (pcs.length === 1 && pcs[0].page_content.trim() === "")) {
            return (
                <Tooltip title={"LLM Basiswissen - Information prüfen!"}>
                    <IconButton>
                        <GoStarFill style={{fontSize: '12px', color: props.botColors.primary}}/>
                        <GoStar style={{fontSize: '12px', color: props.botColors.primary}}/>
                        <GoStar style={{fontSize: '12px', color: props.botColors.primary}}/>
                    </IconButton>
                </Tooltip>
            )
        }
        
        let have_context = false  // "Kontext-Wissen. Könnte veraltet sein."
        let have_web = false  // "Wissen aus dem Internet"
        pcs.forEach((pc: PageContent) => {
            // pc.metadata.similarity_score
            // pc.page_content
            if (pc.page_content.trim() === "") {
                return
            }
            if (pc.metadata.store === "RAG") {
                have_context = true
            } else if (pc.metadata.store === "rt-rag") {
                have_web = true
            }
        })
        
        if (have_context && have_web) {
            return (
                <Tooltip title={"Kontext-Wissen und Wissen aus dem Internet."}>
                    <IconButton>
                        <GoStarFill style={{fontSize: '12px', color: props.botColors.primary}}/>
                        <GoStarFill style={{fontSize: '12px', color: props.botColors.primary}}/>
                        <GoStarFill style={{fontSize: '12px', color: props.botColors.primary}}/>
                    </IconButton>
                </Tooltip>
            )
        } else if (have_web) {
            return (
                <Tooltip title={"Wissen aus dem Internet."}>
                    <IconButton>
                        <GoStarFill style={{fontSize: '12px', color: props.botColors.primary}}/>
                        <GoStarFill style={{fontSize: '12px', color: props.botColors.primary}}/>
                        <GoStarFill style={{fontSize: '12px', color: props.botColors.primary}}/>
                    </IconButton>
                </Tooltip>
            )
        } else if (have_context) {
            return (
                <Tooltip title={"Kontext-Wissen - Aktualität prüfen."}>
                    <IconButton>
                        <GoStarFill style={{fontSize: '12px', color: props.botColors.primary}}/>
                        <GoStarFill style={{fontSize: '12px', color: props.botColors.primary}}/>
                        <GoStar style={{fontSize: '12px', color: props.botColors.primary}}/>
                    </IconButton>
                </Tooltip>
            )
        } else {
            return (
                <Tooltip title={"Kein Wissen"}>
                    <IconButton>
                        <GoStar style={{fontSize: '12px', color: props.botColors.primary}}/>
                        <GoStar style={{fontSize: '12px', color: props.botColors.primary}}/>
                        <GoStar style={{fontSize: '12px', color: props.botColors.primary}}/>
                    </IconButton>
                </Tooltip>
            )
        }
    }

    const formatText = (text: string) => {
        const boldMarkdownRegex = /\*\*(.*?)\*\*/g;
        let html = text.replace(boldMarkdownRegex, '<b>$1</b>');

        // Regular expression to find markdown links
        const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
        html = html.replace(linkRegex, "<a href=\"$2\" target=\"_blank\" rel=\"noopener noreferrer\" style=\"color: " + props.botColors.timestamp + ";\">$1</a>");
        
        // Configure DOMPurify to allow target attribute
        return DOMPurify.sanitize(html, {
            ADD_ATTR: ['target', 'rel']
        });
    };

    props.speechUtterance.onend = () => {
        setSpeaking(false)
    }

    // enable autoscroll after each message
    useEffect(() => {
        if (dummyRef && dummyRef.current && bodyRef && bodyRef.current) {
            bodyRef.current.scrollTo({
                top: dummyRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    }, [props.messages, props.showWaitForResponse]);

    useEffect(() => {
        setSpeaking(window.speechSynthesis.speaking)
        // eslint-disable-next-line
    }, [window.speechSynthesis.speaking]);

    return (
        <Box sx={messageContainer} ref={bodyRef}>
            {props.messages.map((chat: ChatMessage, index: number)=> (
                <>
                    <ListItem 
                        key={chat.message + "-"} 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: chat.sender === "bot" ? 'flex-start' : 'flex-end',
                            paddingLeft: '0',
                            paddingRight: '0'
                        }}
                    >
                        <Box 
                            sx={(chat.sender === "bot" ? 
                                ({
                                    ...messageContainerBot,
                                    background: props.botColors.bot,
                                    color: props.botColors.botText,
                                }) : 
                                ({
                                    ...messageContainerUser,
                                    background: props.botColors.primary,
                                    color: props.botColors.light,
                                    alignSelf: 'flex-end'
                                })
                            )}
                        >
                            <ListItemText 
                                primary={
                                    <>
                                        {(speaking ?
                                            <FaStop color={chat.sender === "bot" ? props.botColors.botText : props.botColors.light} size={"0.5em"} onClick={()=>stopReading()}/> :
                                            <FaPlay color={chat.sender === "bot" ? props.botColors.botText : props.botColors.light} size={"0.5em"} onClick={()=>readThis(chat.message)}/>
                                        )}&nbsp;
                                        {decodeUnicode(chat.message || "").split('<br/>').map((textEl: string) => (
                                            <><span dangerouslySetInnerHTML={{ __html: formatText(textEl) }} /><br/></>
                                        ))}
                                    </>
                                } 
                                secondary = {<>
                                    {index > 2 && chat.sender === "bot" &&
                                        <Grid textAlign={'right'}>
                                            <Grid item >
                                                {/*<Chip
                                                    label={(chat.query_about.length > 0 ? "#" + chat.query_about : "")}
                                                    sx={{
                                                        marginRight: '25px',
                                                        marginBottom: '3px',
                                                        backgroundColor: '#f0f0f0',
                                                        color: '#333',
                                                        borderRadius: '5px',
                                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                    }}
                                                />*/}

                                                {getSourceIndicatorWithStars(chat.sources)}
                                                
                                                <Tooltip title="Feedback">
                                                    <IconButton onClick={()=>props.chatWithBot("Ich möchte zum Chatverlauf bis hierhin Feedback an die zuständige Stelle geben.", "Feedback-1")} >
                                                        <BiMessageEdit style={{ fontSize: '12px' }}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Antwort überprüfen">
                                                    <IconButton onClick={()=>props.chatWithBot("Überprüfe deine letzte Antwort.")} >
                                                        <PiMagnifyingGlassBold style={{ fontSize: '12px' }}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid  color={props.botColors.botText} textAlign={'right'}>
                                        {chat.query_about && chat.query_about === "Feedback-2" && 
                                            <Grid container>
                                                <Grid item >
                                                    <Button sx={{ width: '150px', fontSize: '8px', marginRight: '15px', marginTop: '15px', marginBottom: '15px', backgroundColor: props.botColors.primary }} variant="contained"
                                                            onClick={()=>props.chatWithBot("", "Feedback-3-send")}>
                                                        Feedback senden
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        }
                                        {chat.query_about && chat.query_about.includes("ask_sending_personal_data") && 
                                            <Grid container>
                                                <Grid item >
                                                    <Button sx={{ width: '75px', fontSize: '8px', marginRight: '15px', marginTop: '15px', marginBottom: '15px', backgroundColor: props.botColors.primary }} variant="contained"
                                                            onClick={()=>props.chatWithBot(chat.query_about.replace("ask_sending_personal_data ", ""),
                                                                "agree_sending_personal_data")
                                                            }
                                                    >
                                                        Ja
                                                    </Button> &nbsp;
                                                    <Button sx={{ width: '75px', fontSize: '8px', marginRight: '15px', marginTop: '15px', marginBottom: '15px', backgroundColor: props.botColors.primary }} variant="contained"
                                                            onClick={()=>props.chatWithBot("Ich möchte die Anfrage doch nicht ausführen.",
                                                                "dont_send_personal_data")
                                                            }
                                                    >
                                                        Nein
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        }
                                        {index === 0 && props.nlpTask === "CIS" && 
                                            <Grid container>
                                                    <Grid item >
                                                        <Button sx={{ width: '150px', fontSize: '8px', marginRight: '15px', marginTop: '15px', marginBottom: '15px', backgroundColor: props.botColors.primary }} variant="contained" onClick={()=>props.chatWithBot("Los geht's!")}>
                                                            Los geht's!
                                                        </Button>
                                                    </Grid>
                                            </Grid>
                                        }
                                        {index > 2 && chat.sender === "bot" && index === props.messages.length - 1 && props.nlpTask === "CIS" && 
                                            <Grid container>
                                                <Grid item >
                                                    <Button sx={{ width: '150px', fontSize: '8px', marginRight: '15px', marginTop: '15px', marginBottom: '15px', backgroundColor: props.botColors.primary }} variant="contained" onClick={()=>props.chatWithBot("Ich möchte das Interview beenden. Zeige mir, was du bisher an Informationen erfasst hast.", "CIS-end")}>
                                                        Interview beenden.
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        }
                                        
                                        <Typography sx={{fontSize: "10px", color: props.botColors.timestamp }}>
                                            {chat.best_sources !== "" ? getBestSourcesElements(chat.best_sources) : <></>}
                                            {chat.timestamp}
                                            {false && chat.cost && chat.cost > 0 && chat.sender === "bot" && ", " + (Math.round((chat.cost * 100)*1000) / 1000) + "ct"}
                                        </Typography>
                                    </Grid>
                                </>}
                            />
                        </Box>
                    </ListItem>
                    {chat.options ? (
                        <ListItem key={chat.message} alignItems="flex-start" sx={{ paddingLeft: "0px", paddingRight: "0px", maxWidth: "700px" }}>
                            {chat.sender === "bot" && chat.options && <ListItemIcon><EastOutlinedIcon /></ListItemIcon>}


                            {chat.options.map(option => (
                                <ListItemButton sx={messageContainerOptionsP}
                                    onClick={e => props.optionClick(e)}
                                    data-id={option}
                                    key={option}
                                >
                                    {option}
                                </ListItemButton>
                            ))}
                        </ListItem>
                    ) : null}

                </>
            ))}

            {props.showWaitForResponse && props.streamAnswer === "" && 
                <ListItem key={"w8msg-"} alignItems="center" sx={{ width: '100%' }}>

                    <ListItemText 
                        primary={<>
                            <RandomFunnyLoadingMessages w8Messages={ props.w8Messages }/>
                            <LinearProgress sx={{width: '50%', margin: '0 auto',
                                backgroundColor: props.botColors.bot,
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: props.botColors.primary, // Custom progress bar color
                                },}}/>
                            </>
                        } 
                        sx={messageContainerWait}
                        secondary={""}
                    />
                </ListItem>
            } 

            {props.showWaitForResponse && props.streamAnswer !== "" &&
                <>
                    <ListItem
                        key={"streamMsg-"}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            paddingLeft: '0',
                            paddingRight: '0'
                        }}
                    >
                        <Box
                            sx={{
                                ...messageContainerBot,
                                background: props.botColors.bot,
                                color: props.botColors.botText,
                            }}
                        >
                            <ListItemText
                                primary={
                                    <>
                                        {(speaking ?
                                                <FaStop color={props.botColors.botText} size={"0.5em"} onClick={()=>stopReading()}/> :
                                                <FaPlay color={props.botColors.botText} size={"0.5em"} onClick={()=>readThis(props.streamAnswer)}/>
                                        )}&nbsp;
                                        {decodeUnicode(props.streamAnswer).split('<br/>').map((textEl: string) => (
                                            <><span dangerouslySetInnerHTML={{ __html: formatText(textEl) }} /><br/></>
                                        ))}
                                    </>
                                } secondary={""}
                            />
                        </Box>
                    </ListItem>  
                </>
            }
            
            
            <Box key={"dummy-scroll-ref"} ref={dummyRef} sx={{
                display: "flex", flexDirection: "column"
            }}></Box>
        </Box>
    );
};

export default Chats;
