import {
    Grid, IconButton,
    Paper,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import InputBase from "@mui/material/InputBase";
import {IoIosSend} from "react-icons/io";
import ListItem from "@mui/material/ListItem";

export interface IShowroomProps {
}

const chatbots = [
    "53a12f72-a5af-4e2b-b1e2-b53f99e2f7ee", // Hamburg
    "1c0aed13-0b8c-42a4-9c2c-5e106bad0e66", // Bremen
    "a580e330-09cd-4f2c-8113-be7a5c2a7e35", // Bremerhaven
    "33f84805-dd03-4afb-8bea-975d2954a22c", // Lübeck
    "4ada38a9-5d2e-4757-9714-3d89e0db25ce", // Schwerin
    "14e725c5-2333-43d1-bc8f-08c8306aa266", // Rostock
    "2af858f8-ec3f-438e-b9fd-d88366b69e85", // Lüneburg
    "08c98c95-c194-4d93-888d-2f7d391f77d0", // Oldenburg
    "25e69482-3359-43f9-897f-f945bdf87c3b", // Kiel
    "0b2a3e15-c314-4c2b-9978-1d19dd31ee99", // Flensburg
    "25f1c3a4-0732-4a4e-b7d5-a0267543c11b", // Hannover
    "3341a870-5b95-4a7c-838c-5bc88fc18bcf", // Osnabrück
    "9dfdc55f-f1d9-4306-9428-fda3b0c20ab0", // Münster
    "68b4791d-0a02-47ee-84f7-7c87a2e30398", // Bielefeld
    "80dd1815-a41f-4b7a-b0b4-ade507598eee", // Magdeburg
    "097cc3ad-d4fb-43b0-ba55-728c8c24f863", // Braunschweig

]

const Showroom: React.FunctionComponent<IShowroomProps> = (props: IShowroomProps) => {

    const [userResponse, setUserResponse] = useState<string>("");

    const iframeRefs = React.useRef<{ [key: string]: HTMLIFrameElement | null }>({});

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserResponse(e.target.value);
    };

    const handleSubmit = (theQuestion: string, extraParam?: string) => {
        chatbots.forEach((botId: string) => {
            sendQuestionToChatbot(botId, theQuestion)
        })
    }

    // Function to send a question to the chatbot iframe
    const sendQuestionToChatbot = (botId: string, questionToAsk: string) => {
        const iframe = iframeRefs.current[botId];
        if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage(
                { type: 'questionToAsk', message: questionToAsk }, // Message format
                '*' // Target origin
            );
        }
    };

    return (
        <>
            <Grid container justifyContent="center" spacing={2}>
                {chatbots.map((botId:string, idx: number) => {
                    
                    return (     
                        <>
                            {idx === 8 &&
                                <Grid container item xs={4} sx={{ }}>
                                    <ListItem alignItems="center" sx={{ justifyContent: "center", alignItems: "center", padding: "20px", backgroundColor: "rgba(255,255,255, 0)" }}>
                                        <h2>Chat mit 16 Städten</h2>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" sx={{ margin: "20px", padding: "0px", backgroundColor: "rgba(255,255,255, 0)" }}>
                                        <Paper
                                            component="form"
                                            sx={{
                                                p: '8px 8px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: "100%",
                                                borderBottomLeftRadius: "10px",
                                                backgroundColor: "white",
                                                borderBottomRightRadius: "10px",
                                                borderTopRightRadius: "10px",
                                                borderTopLeftRadius: "10px",
                                            }}
                                        >
                                            
                                            <InputBase
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder={"Ihre EIngabe"}
                                                inputProps={{ 'aria-label': 'user input' }}
                                                onChange={e => handleInputChange(e)}
                                                value={userResponse}
                                            />
                                            <IconButton sx={{color: "gray"}} onClick={
                                                () => handleSubmit(userResponse, "")
                                            }>
                                                 <IoIosSend />
                                            </IconButton>
                                        </Paper>
                                    </ListItem>
                                    <ListItem alignItems="flex-start" sx={{ padding: "20px", backgroundColor: "rgba(255,255,255, 0)" }}>
                                        
                                    </ListItem>
                                </Grid>
                            }
                            <Grid container item xs={2} sx={{ height: '450px', zoom: 0.65 }}>
                                    <iframe
                                        ref={(el) => (iframeRefs.current[botId] = el)} // Assign ref to each iframe
                                        title={"HelperBot"}
                                        src={"https://int.botbucket.de/bot/" + botId + "/chat/undecorated"}
                                        style={{borderWidth: "0px", width: "500px"}}
                                        allowTransparency
                                    />
                            </Grid>
                            
                        </>
                    )
                })}
            </Grid>
        </>
    );
}

export default Showroom;